<!-- 所有 el-autocomplete 输入框需要做清空时的处理，需要与后端确认置空id是否可行 -->
<template>
  <div class="formBox" v-loading="loading">
    <div
      class="loading_po"
      v-loading="loading"
      element-loading-text="请稍后..."
      element-loading-spinner="el-icon-loading"
    ></div>
    <div style="display: flex; align-items: center">
      <h4 style="color: #146aff; padding: 0px 20px 0px 30px">基本信息</h4>

      <!-- <el-checkbox v-model="checked" @change="clcikChecked">通用资讯</el-checkbox> -->
    </div>
    <el-form ref="form" :model="form" label-width="120px" :rules="updatePwdRules">
      <el-form-item label="资讯类型" prop="informationType">
        <div style="margin-top: 10px">
          <el-radio-group v-model="form.informationType">
            <el-radio :label="1">应用场景</el-radio>
            <el-radio :label="2">数智技术</el-radio>
            <el-radio :label="3">行业动态</el-radio>
            <el-radio :label="4">案例视频</el-radio>
            <el-radio :label="5">数智产品</el-radio>
            <el-radio :label="6">服务商</el-radio>
            <el-radio :label="7">政策解读</el-radio>
            <el-radio :label="8">组织人才</el-radio>
            <el-radio :label="10">数智案例</el-radio>
            <el-radio :label="11">场景知识</el-radio>
            <!-- <el-radio :label="12">电信专区</el-radio> -->
          </el-radio-group>
        </div>
      </el-form-item>
      <div v-if="form.informationType!=12">
        <el-form-item label="内容类别" prop="contentType">
        <el-checkbox @change="contentTypeChange" v-model="contentType">AI+资讯</el-checkbox>
      </el-form-item>
      </div>
      
      <div v-if="form.informationType == 5">
        <el-form-item label="产品类型" prop="informationType">
          <div style="margin-top: 10px">
            <el-radio-group v-model="form.productType">
              <el-radio label="软件类型">软件类型</el-radio>
              <el-radio label="硬件类型">硬件类型</el-radio>
              <el-radio label="解决方案">解决方案</el-radio>
              <el-radio label="数据服务">数据服务</el-radio>
              <el-radio label="产业服务">产业服务</el-radio>
              <el-radio label="其他产品">其他产品</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </div>
      <div v-if="form.informationType === 7">
        <el-form-item label="内容名称" prop="informationName">
          <!-- <el-input
            v-model="form.informationName"
            @blur="informationNameBlur"
            placeholder="请输入内容名称"
          ></el-input> -->
          <el-autocomplete
            v-model="form.informationName"
            :fetch-suggestions="querySearchInformationName"
            placeholder="请输入政策标题"
            @select="handleSelectInformationName"
          >
            <template slot-scope="{ item }">
              <div class="name">
                <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                  item.informationName
                }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
      </div>
      <div v-else-if="form.informationType === 10">
        <el-form-item label="案例名称" prop="informationName">
          <el-autocomplete
            v-model="form.informationName"
            :fetch-suggestions="querySearchInformationName"
            placeholder="请输入案例名称"
            @select="handleSelectInformationName"
          >
            <template slot-scope="{ item }">
              <div class="name">
                <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                  item.informationName
                }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
      </div>
      <div v-else-if="form.informationType === 11">
        <el-form-item label="场景名称" prop="informationName">
          <el-input v-model="form.informationName" placeholder="请输入场景名称"></el-input>
        </el-form-item>
      </div>
      <div v-else-if="form.informationType!=12">
        <el-form-item label="资讯名称" prop="informationName">
          <!-- <el-input
            @blur="informationNameBlur"
            v-model="form.informationName"
            placeholder="请输入资讯名称"
          ></el-input> -->
          <el-autocomplete
            v-model="form.informationName"
            :fetch-suggestions="querySearchInformationName"
            placeholder="请输入报告名称"
            @select="handleSelectInformationName"
          >
            <template slot-scope="{ item }">
              <div class="name">
                <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                  item.informationName
                }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
      </div>
      <div v-if="form.informationType !== 10&&form.informationType!=12">
        <div v-if="form.informationType === 7">
          <el-form-item label="一句话描述" prop="informationBrief">
            <div class="txtree">
              <el-input
                v-model="form.informationBrief"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                placeholder="请输入一句话描述"
                maxlength="100"
                show-word-limit
              />
            </div>
          </el-form-item>
        </div>
        <div v-else-if="form.informationType!=12">
          <el-form-item label="一句话介绍:" prop="informationBrief">
            <div class="txtree">
              <el-input
                v-model="form.informationBrief"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                placeholder="请输入一句话简介"
                maxlength="100"
                show-word-limit
              />
            </div>
          </el-form-item>
        </div>
      </div>
      <div v-if="form.informationType!=12">
        <el-form-item :label="getPicLabel(form.informationType)" prop="imageList">
        <div class="cover">
          <div class="img_box">
            <uploadCover
              :fileList="form.imageList"
              @uploadOneImg="uploadOneImg"
              @removeImg="removeImg"
            ></uploadCover>
            <!-- <span style="color: #146aff">建议图片整体尺寸为442*226 px大小不超过12MB</span> -->
            <span style="color: #cfd3db">建议上传图片整体尺寸为229*131 px，大小不超过8MB</span>
          </div>
          <div v-if="imageUrlList.length > 0">缩略图</div>
          <div v-if="imageUrlList.length > 0" class="thumbnail">
            <div class="thumbnail_box">
              <img :src="imageUrlList[0]" alt="" />
              <div>图片尺寸：229*131 px</div>
              <div>图片大小：4MB</div>
              <div>展示位置：首页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="imageUrlList[0]" alt="" />
              <div>图片尺寸：229*131 px</div>
              <div>图片大小：5MB</div>
              <div>展示位置：文章详情页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="imageUrlList[0]" alt="" />
              <div>图片尺寸：229*131 px</div>
              <div>图片大小：10MB</div>
              <div>展示位置：首页banner区域</div>
            </div>
          </div>
        </div>
      </el-form-item>
      </div>
   
      <div v-if="form.informationType == 4 || form.informationType == 5">
        <el-form-item label="视频" prop="videoList">
          <div class="cover">
            <div class="img_box" v-if="form.videoResults">
              <uploadVideo
                :videos="form.videoResults"
                :videosId="form.videos"
                @uploadOneImg="uploadOneImgvideo"
                @removeImg="removeImgvideo"
              ></uploadVideo>
            </div>
            <div class="img_box" v-else>
              <uploadVideo
                @uploadOneImg="uploadOneImgvideo"
                @removeImg="removeImgvideo"
              ></uploadVideo>
            </div>
            <div v-if="form.videoResults">缩略图</div>
            <div v-if="form.videoResults" class="thumbnail">
              <div class="thumbnail_box">
                <img :src="form.videoResults[0].coverImage" alt="" />
                <div>视频尺寸：442*226 px</div>
                <div>图片大小：4MB</div>
                <div>展示位置：首页</div>
              </div>
              <div class="thumbnail_box">
                <img :src="form.videoResults[0].coverImage" alt="" />
                <div>视频尺寸：442*226 px</div>
                <div>图片大小：5MB</div>
                <div>展示位置：文章详情页</div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="视频播放次数">
          <div class="number_top">
            <span>{{ form.loopCount }}</span>
          </div>
          <div class="number_loop">
            <el-input-number v-model="form.loopCount" :min="1" :max="1000000"></el-input-number>
            <el-switch
              style="margin-left: 20px"
              v-model="form.loopStatus"
              active-color="#3562ff"
              active-value="1"
              inactive-value="0"
            >
            </el-switch>
            <div style="position: relative; top: 10px; left: 10px" v-if="form.loopStatus == 1">
              已启用
            </div>
          </div>
          <span style="color: #bebebe">此播放次数为虚假数据，如启用，需展示在前台</span>
        </el-form-item>
        <div>
          <el-form-item label="内容附件" prop="">
            <div class="row">
              <div class="input_upload_box">
                <el-input
                  style="width: 440px; height: 38px"
                  v-model="form.policyInterpretationFile"
                  placeholder="请添加内容附件"
                  readonly
                ></el-input>
                <i
                  v-if="form.policyInterpretationFile"
                  class="el-icon-error"
                  @click="removeFile"
                ></i>
                <UploadFile
                  ref="upload_child"
                  class="upload_box"
                  @change="uploadFileSuccess"
                  :lookUrl="form.policyInterpretationFile"
                  :saveUrl="form.policyInterpretationFile"
                ></UploadFile>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div v-else-if="form.informationType === 10 || form.informationType === 11">
        <el-form-item label="视频" prop="videoList">
          <div class="cover">
            <div class="img_box" v-if="form.videoResults">
              <uploadVideo
                :videos="form.videoResults"
                @uploadOneImg="uploadOneImgvideo"
                @removeImg="removeImgvideo"
              ></uploadVideo>
              <span style="color: #146aff">建议视频整体尺寸为442*226 px，大小不超过20MB</span>
            </div>
            <div class="img_box" v-else>
              <uploadVideo
                @uploadOneImg="uploadOneImgvideo"
                @removeImg="removeImgvideo"
              ></uploadVideo>
              <span style="color: #146aff">建议视频整体尺寸为442*226 px，大小不超过20MB</span>
            </div>
            <div v-if="form.videoResults">缩略图</div>
            <div v-if="form.videoResults" class="thumbnail">
              <div class="thumbnail_box">
                <img :src="form.videoResults[0].coverImage" alt="" />
                <div>视频尺寸：442*226 px</div>
                <div>图片大小：4MB</div>
                <div>展示位置：首页</div>
              </div>
              <div class="thumbnail_box">
                <img :src="form.videoResults[0].coverImage" alt="" />
                <div>视频尺寸：442*226 px</div>
                <div>图片大小：5MB</div>
                <div>展示位置：文章详情页</div>
              </div>
            </div>
          </div>
        </el-form-item>
      </div>
      <!-- 富文本编辑器  7、11 -->
      <div v-if="form.informationType === 7">
        <el-form-item label="政策简介" prop="policyBrief">
          <div class="txtree">
            <el-input
              v-model="form.policyBrief"
              type="textarea"
              :autosize="{ minRows: 9, maxRows: 10 }"
              placeholder="请输入政策简介"
              maxlength="200"
              show-word-limit
            />
          </div>
        </el-form-item>
        <el-form-item label="政策预览" prop="policyInterpretation">
          <div class="editorbar_box">
            <div class="editBar">
              <Editorbar v-model="form.policyInterpretation" :isClear="false" />
            </div>
            <div class="img_tips">
              <span
                >(建议上传图片，图片的尺寸长为343px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)</span
              >
            </div>
          </div>
        </el-form-item>
      </div>
      <div v-else-if="form.informationType === 11">
        <el-form-item label="场景描述" prop="sceneDescription">
          <div class="editorbar_box">
            <div class="editBar">
              <Editorbar v-model="form.sceneDescription" :isClear="false" />
            </div>
          </div>
        </el-form-item>
      </div>
      <div v-if="form.informationType === 10">
        <el-form-item label="案例简介" prop="">
          <Editorbar v-model="form.briefIntroduction" />
          <!-- <el-input
            v-model="form.briefIntroduction"
            placeholder="请输入案例简介"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="1000"
            show-word-limit
          ></el-input> -->
        </el-form-item>
        <el-form-item label="客户痛点" prop="">
          <Editorbar v-model="form.customerPainPoint" />
          <!-- <el-input
            v-model="form.customerPainPoint"
            placeholder="请输入客户痛点"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="1000"
            show-word-limit
          ></el-input> -->
        </el-form-item>
        <el-form-item label="解决方案" prop="">
          <Editorbar v-model="form.customerSolution" />
          <!-- <el-input
            v-model="form.customerSolution"
            placeholder="请输入解决方案"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="1000"
            show-word-limit
          ></el-input> -->
        </el-form-item>
        <el-form-item label="案例成果" prop="">
          <Editorbar v-model="form.caseOutcome" />
          <!-- <el-input
            v-model="form.caseOutcome"
            placeholder="请输入案例成果"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="1000"
            show-word-limit
          ></el-input> -->
        </el-form-item>
      </div>
      <div v-if="form.informationType === 7">
        <el-form-item label="内容附件" prop="">
          <!-- <div class="row">
            <div class="input_upload_box">
              <el-input
                style="width: 440px; height: 38px"
                v-model="form.policyInterpretationFile"
                placeholder="请添加内容附件"
                readonly
              ></el-input>
              <i v-if="form.policyInterpretationFile" class="el-icon-error" @click="removeFile"></i>
              <UploadFile
                ref="upload_child"
                class="upload_box"
                @change="uploadFileSuccess"
                :lookUrl="form.policyInterpretationFile"
                :saveUrl="form.policyInterpretationFile"
              ></UploadFile>
            </div>
          </div> -->
          <div class="file_flex" v-for="(el, i) in policyInterpretationFiles" :key="i">
            <div
              class="name_form"
              style="width: 600px; display: flex; justify-content: space-between"
            >
              <el-input v-model="el.name" placeholder="请添加内容附件" />
              <el-upload
                action
                class="upload-demo"
                ref="upload"
                :show-file-list="false"
                :before-upload="beforeUploadprods"
                :http-request="
                  (res) => {
                    return uploadprods(res, i)
                  }
                "
              >
                <el-button
                  style="position: relative; left: -90px"
                  slot="trigger"
                  size="mini"
                  type="primary"
                  plain
                  >上传附件</el-button
                >
              </el-upload>
            </div>
            <div style="display: flex; font-size: 24px">
              <span v-if="i == 0" style="color: #4e93fb" @click="addUpload"
                ><i class="el-icon-circle-plus"></i
              ></span>
              <span v-if="i > 0" style="color: #ff7575" @click="delUpload(i)"
                ><i class="el-icon-delete"></i
              ></span>
            </div>
          </div>
        </el-form-item>
      </div>
      <div v-if="form.informationType === 6">
        <el-form-item label="关联企业" prop="">
          <div style="display: flex; align-items: flex-end">
            <el-autocomplete
              v-model="form.informationCompanies[0].companyFullName"
              :fetch-suggestions="
                (res, cb) => {
                  return querySearchAsyncaptname(res, cb)
                }
              "
              placeholder="请输入关联企业关键词"
              @select="
                (res) => {
                  return handleSelectcaptname(res)
                }
              "
            >
              <template slot-scope="{ item }">
                <div class="name">
                  <img
                    :src="item.companyLogo"
                    style="border-radius: 28px"
                    width="28px"
                    height="28px"
                    alt=""
                  />
                  <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                    item.companyFullName
                  }}</span>
                </div>
              </template>
            </el-autocomplete>
            <el-tag
              v-if="form.informationCompanies[0].companyFullName"
              style="margin-left: 20px"
              type="info"
              >{{ form.informationCompanies[0].companyFullName }}</el-tag
            >
            <el-checkbox
              style="margin-left: 20px; position: relative; top: 5px"
              v-model="checked1"
              @change="checkedchaneg"
              >可提供本地服务</el-checkbox
            >
          </div>
        </el-form-item>
        <el-form-item label="企业简介:" prop="enterpriseIntro">
          <div class="txtree">
            <el-input
              v-model="form.enterpriseIntro"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 6 }"
              placeholder="请输入一句话简介"
              maxlength="200"
              show-word-limit
            />
          </div>
        </el-form-item>
        <el-form-item label="企业附件:" prop="">
          <div
            class="name_form"
            style="width: 700px; display: flex; justify-content: space-between"
          >
            <el-input v-model="form.companyFile" placeholder="请上传企业附件" />
            <el-upload
              action
              class="upload-demo"
              ref="upload"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :http-request="upload"
            >
              <el-button
                style="position: relative; left: -160px"
                slot="trigger"
                size="mini"
                type="primary"
                plain
                >上传附件</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
      </div>
      <div v-if="form.informationType == 5">
        <el-form-item label="产品功能:" prop="informationProducts">
          <div class="general_inputs">
            <div class="input_flex" v-for="(item, index) in form.informationProducts" :key="index">
              <div style="display: flex; align-items: center">
                <img-big-upload
                  :imageShow.sync="item.productImage"
                  :addTitleShow="false"
                  @removeImg="
                    (res) => {
                      return removeImgDianprod(res, index)
                    }
                  "
                  @uploadOneImgShow="
                    (res) => {
                      return uploadOneImgShowDianprod(res, index)
                    }
                  "
                />
                <div class="textarea" style="margin-left: 10px">
                  <el-input
                    v-model="item.productFunctionDescribe"
                    type="textarea"
                    placeholder="请输入功能描述"
                    maxlength="10"
                    show-word-limit
                  />
                </div>
              </div>
              <div style="display: flex; font-size: 24px; margin-left: 20px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addDescribe"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delDescribe(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="产品简介:" prop="productBrief">
          <div class="txtree">
            <el-input
              v-model="form.productBrief"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 6 }"
              placeholder="请输入一句话简介"
              maxlength="100"
              show-word-limit
            />
          </div>
        </el-form-item>
        <el-form-item label="产品附件:" prop="productFile">
          <div
            class="name_form"
            style="width: 700px; display: flex; justify-content: space-between"
          >
            <el-input v-model="form.productFile" placeholder="请上传企业附件" />
            <el-upload
              action
              class="upload-demo"
              ref="upload"
              :show-file-list="false"
              :before-upload="beforeUploadprod"
              :http-request="uploadprod"
            >
              <el-button
                style="position: relative; left: -160px"
                slot="trigger"
                size="mini"
                type="primary"
                plain
                >上传附件</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="应用价值:" prop="">
          <div class="general_inputs">
            <div
              class="input_flex"
              v-for="(item, index) in form.informationProductValues"
              :key="index"
            >
              <div>
                <div class="valueType_flex">
                  <div style="margin-right: 20px">价值类型</div>
                  <el-radio-group v-model="item.valueType">
                    <el-radio label="提升">提升</el-radio>
                    <el-radio label="降低">降低</el-radio>
                  </el-radio-group>
                </div>
                <div class="textarea">
                  <el-input
                    v-model="item.valueTitle"
                    type="textarea"
                    placeholder="请输入应用价值标题"
                    maxlength="10"
                    show-word-limit
                  />
                </div>
                <div style="display: flex; align-items: center; padding-top: 20px">
                  <div class="percentage">
                    <el-input placeholder="" v-model="item.input1">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                  <div style="padding: 0px 10px">至</div>
                  <div class="percentage">
                    <el-input placeholder="" v-model="item.input2">
                      <template slot="append">%</template>
                    </el-input>
                  </div>
                </div>
              </div>

              <div style="display: flex; font-size: 24px; margin-left: 20px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addvalueTitle"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delvalueTitle(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="产品热度">
          <div class="number_loop">
            <el-input-number v-model="form.productHeat" :min="1" :max="1000000"></el-input-number>
            <el-switch
              style="margin-left: 20px"
              v-model="form.productHeatStatus"
              active-color="#3562ff"
              :active-value="1"
              :inactive-value="0"
            >
            </el-switch>
            <div style="position: relative; top: 10px; left: 10px" v-if="form.loopStatus == 1">
              已启用
            </div>
          </div>
          <span style="color: #bebebe">此设置为虚假数据，如启用，需展示在前台</span>
        </el-form-item>
      </div>

      <div style="border-top: 20px solid #f6f7fb">
        <h4 style="color: #146aff; padding-left: 30px">发布机构信息</h4>
        <!-- <el-form-item label="发布机构" prop="" v-if="form.informationType === 7">
          <el-input v-model="form.policyIssuingAgency" placeholder="请输入发布机构"></el-input>
        </el-form-item> -->

        <div>
          <el-form-item label="发布机构/人" prop="issueName">
            <el-autocomplete
              v-model="form.issueName"
              :fetch-suggestions="querySearchAsyncapt"
              placeholder="请输入发布机构关键词"
              @select="handleSelectcapt"
            >
              <template slot-scope="{ item }">
                <div class="name">
                  <img
                    :src="item.issueLogo"
                    style="border-radius: 28px"
                    width="28px"
                    height="28px"
                    alt=""
                  />

                  <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                    item.issueName
                  }}</span>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="logo" prop="logo">
            <div class="img_tips flex">
              <uploadLogo :imageShow="form.logo" @uploadOneImgShow="uploadOneImgShow"></uploadLogo>
              <span>(建议整体尺寸为200*200px，图片格式应为jpg、jpeg、png，图片应小于8MB)</span>
            </div>
          </el-form-item>
          <!-- 发布时间新增 -->
          <div class="issueTime" >
            <el-form-item label="来源" style="width: 240px; margin-right: 120px">
              <el-input
                v-model="form.resource"
                placeholder="请输入来源"
                style="width: 240px"
              ></el-input>
            </el-form-item>
            <div v-if="form.informationType < 4 || form.informationType > 6">
              <el-form-item label="发布时间" prop="issueTime">
                <el-date-picker
                  v-model="form.issueTime"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  prefix-icon="el-icon-date"
                  class="picker"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
       
        </div>

        <div v-if="form.informationType < 4 || form.informationType === 7">
          <!-- <el-form-item label="发布时间">
            <el-date-picker
              v-model="form.issueTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              prefix-icon="el-icon-date"
              class="picker"
            >
            </el-date-picker>
          </el-form-item> -->
          <div v-if="form.informationType !== 7">
            <el-form-item label="资讯描述" prop="informationIntro">
              <div class="editBarBox">
                <div class="editBar">
                  <Editorbar v-model="form.informationIntro" :isClear="isClear" />
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
        <div v-if="form.informationType == 3">
          <el-form-item label="内容附件:" prop="productFile">
            <div class="file_flex" v-for="(el, i) in industryDynamicsFiles" :key="i">
              <div
                class="name_form"
                style="width: 600px; display: flex; justify-content: space-between"
              >
                <el-input v-model="el.name" placeholder="请上传企业附件" />
                <el-upload
                  action
                  class="upload-demo"
                  ref="upload"
                  :show-file-list="false"
                  :before-upload="beforeUploadprods"
                  :http-request="
                    (res) => {
                      return uploadprods(res, i)
                    }
                  "
                >
                  <el-button
                    style="position: relative; left: -90px"
                    slot="trigger"
                    size="mini"
                    type="primary"
                    plain
                    >上传附件</el-button
                  >
                </el-upload>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="i == 0" style="color: #4e93fb" @click="addUpload"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="i > 0" style="color: #ff7575" @click="delUpload(i)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>

            <span style="color: #909399">支持doc、docx、xls、xlsx、ppt、pptx、pdf格式</span>
          </el-form-item>
        </div>
      </div>
      <div
        style="border-top: 20px solid #f6f7fb; padding-top: 30px"
        v-if="form.informationType >= 3 && form.informationType !== 7&&form.informationType!=12"
      >
        <h4 style="color: #146aff; padding-left: 30px">关联信息</h4>
        <div v-if="form.informationType !== 6">
          <div v-if="form.informationType === 10">
            <el-form-item label="案例地区" prop="">
              <div class="row">
                <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.city" placeholder="请选择市" @change="districtchange">
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <!-- <el-select v-model="form.district" placeholder="请选择区">
              <el-option
                v-for="item in districtList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select> -->
              </div>
            </el-form-item>
            <el-form-item label="关联产品" prop="">
              <el-input v-model="form.relatedProducts" placeholder="请输入关联产品名称"></el-input>
            </el-form-item>
            <el-form-item label="案例日期" prop="">
              <el-date-picker
                v-model="form.caseDate"
                type="datetime"
                placeholder="请选择案例日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                prefix-icon="el-icon-date"
                class="picker"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所属企业" prop="">
              <div
                class="input_flex"
                style="display: flex"
                v-for="(item, index) in form.informationCompanies"
                :key="index"
              >
                <div>
                  <div class="name_form" style="padding-bottom: 10px">
                    <el-autocomplete
                      v-model="item.companyFullName"
                      :fetch-suggestions="
                        (res, cb) => {
                          return querySearchAsyncapts3(res, cb, index)
                        }
                      "
                      placeholder="请输入关联企业关键词"
                      @select="
                        (res) => {
                          return handleSelectcapts3(res, index)
                        }
                      "
                    >
                      <template slot-scope="{ item }">
                        <div class="name">
                          <img
                            :src="item.companyLogo"
                            style="border-radius: 28px"
                            width="28px"
                            height="28px"
                            alt=""
                          />
                          <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                            item.companyFullName
                          }}</span>
                        </div>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
                <div style="display: flex; font-size: 24px" v-if="form.informationType !== 10">
                  <span v-if="index == 0" style="color: #4e93fb" @click="addenterprise"
                    ><i class="el-icon-circle-plus"></i
                  ></span>
                  <span v-if="index > 0" style="color: #ff7575" @click="delenterprise(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                </div>
                <el-tag v-if="item.companyFullName" style="margin-left: 20px" type="info">{{
                  item.companyFullName
                }}</el-tag>
              </div>
            </el-form-item>
          </div>
          <div v-else>
            <!-- <el-form-item label="关联企业:" prop="">
              <div class="txtree">
                <el-input
                  v-model="form.customer_company_id"
                  placeholder="请输入关联企业关键词"
                  show-word-limit
                />
              </div>
            </el-form-item> -->
            <el-form-item label="关联企业" prop="">
              <div style="display: flex; align-items: flex-end">
                <el-autocomplete
                  v-model="form.informationCompanies[0].companyFullName"
                  :fetch-suggestions="
                    (res, cb) => {
                      return querySearchAsyncaptname(res, cb)
                    }
                  "
                  placeholder="请输入智选企业名称关键词"
                  @select="
                    (res) => {
                      return handleSelectcaptname(res)
                    }
                  "
                >
                  <template slot-scope="{ item }">
                    <div class="name">
                      <img
                        :src="item.companyLogo"
                        style="border-radius: 28px"
                        width="28px"
                        height="28px"
                        alt=""
                      />
                      <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                        item.companyFullName
                      }}</span>
                    </div>
                  </template>
                </el-autocomplete>
                <el-tag
                  v-if="form.informationCompanies[0].companyFullName"
                  style="margin-left: 20px"
                  type="info"
                  >{{ form.informationCompanies[0].companyFullName }}</el-tag
                >
                <!-- <el-checkbox
                  style="margin-left: 20px; position: relative; top: 5px"
                  v-model="checked1"
                  @change="checkedchaneg"
                  >可提供本地服务</el-checkbox
                > -->
              </div>
            </el-form-item>
            <!-- <el-form-item label="关联企业" prop="">
              <div
                class="input_flex"
                style="display: flex"
                v-for="(item, index) in form.informationCompanies"
                :key="index"
              >
                <div>
                  <div class="name_form" style="padding-bottom: 10px">
                    <el-autocomplete
                      v-model="item.companyFullName"
                      :fetch-suggestions="
                        (res, cb) => {
                          return querySearchAsyncapts3(res, cb, index)
                        }
                      "
                      placeholder="请输入关联企业关键词"
                      @select="
                        (res) => {
                          return handleSelectcapts3(res, index)
                        }
                      "
                    >
                      <template slot-scope="{ item }">
                        <div class="name">
                          <img
                            :src="item.companyLogo"
                            style="border-radius: 28px"
                            width="28px"
                            height="28px"
                            alt=""
                          />
                          <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                            item.companyFullName
                          }}</span>
                        </div>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
                <div style="display: flex; font-size: 24px" v-if="form.informationType !== 10">
                  <span v-if="index == 0" style="color: #4e93fb" @click="addenterprise"
                    ><i class="el-icon-circle-plus"></i
                  ></span>
                  <span v-if="index > 0" style="color: #ff7575" @click="delenterprise(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                </div>
                <el-tag v-if="item.companyFullName" style="margin-left: 20px" type="info">{{
                  item.companyFullName
                }}</el-tag>
              </div>
            </el-form-item> -->
          </div>
        </div>
        <div v-if="form.informationType === 10">
          <el-form-item label="客户企业" prop="">
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.informationCompanyVoList"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.companyFullName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchSmartCompany(res, cb, index)
                      }
                    "
                    placeholder="请输入客户企业关键词"
                    @select="
                      (res) => {
                        return handleSelectSmartCompany(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                          item.companyFullName
                        }}</span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addCustomEnterprise"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delCustomEnterprise(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item
            v-if="
              form.informationType != 4 && form.informationType != 3 && form.informationType != 11
            "
            label="关联案例"
            prop="facilitatorResults"
          >
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.facilitatorResults"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.informationName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchAsyncapts(res, cb, index)
                      }
                    "
                    placeholder="输入关联案例内容名称关键词"
                    @select="
                      (res) => {
                        return handleSelectcapts(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <img
                          :src="item.companyLogo"
                          style="border-radius: 28px"
                          width="28px"
                          height="28px"
                          alt=""
                        />
                        <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                          item.informationName
                        }}</span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addareas"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delareas(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            v-if="form.informationType != 5 && form.informationType != 3"
            label="关联产品"
            prop="productResults"
          >
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.productResults"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.informationName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchAsyncapts1(res, cb, index)
                      }
                    "
                    placeholder="请输入产品资讯关键词"
                    @select="
                      (res) => {
                        return handleSelectcapts1(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                          item.informationName
                        }}</span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addareasprod"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delareasprod(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="关联专家" prop="" v-if="form.informationType != 3">
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.specialistResults"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.specialistName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchAsyncapts2(res, cb, index)
                      }
                    "
                    placeholder="请输入关联专家关键词"
                    @select="
                      (res) => {
                        return handleSelectcapts2(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                          item.name
                        }}</span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addareaspeci"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delareaspeci(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="关联技术" prop="" v-if="form.informationType === 11">
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.technologyVos"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.technologyName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchTechnology(res, cb, index)
                      }
                    "
                    placeholder="请输入关联技术关键词"
                    @select="
                      (res) => {
                        return handleSelectTechnology(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <span class="addr" style="position: relative; top: -8px; left: 5px">
                          {{ item.technologyName }}
                        </span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addTechnology"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delTechnology(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>

      <div style="border-top: 20px solid #f6f7fb" class="pictures">
        <h4 class="h4_share">小程序分享链接信息</h4>
        <el-form-item label="分享链接标题:" prop="">
          <el-input v-model="form.shareTitle" placeholder="请输入分享链接标题" />
        </el-form-item>
        <el-form-item label="分享链接图片:" prop="">
          <div class="pictures_box_left">
            <img-big-upload
              :imageShow.sync="form.shareImage"
              :addTitleShow="false"
              @removeImg="removeImgDians"
              @uploadOneImgShow="uploadOneImgShowDians"
            />
          </div>
          <div class="img_tips">
            <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于8MB)</span>
          </div>
        </el-form-item>
      </div>
    </el-form>
    <div class="editInformation" v-if="activeId">
      <div class="item">编辑人：{{ form.createName }}</div>
      <div class="item">编辑时间：{{ form.createTime }}</div>
      <div class="item">发送人：{{ form.updateName }}</div>
      <div class="item">发送时间：{{ form.updateTime }}</div>
    </div>
    <div class="button_box">
      <!-- <div class="send button" @click="clickSend">发送</div> -->
      <!-- <div class="preview button" @click="clickPreview">预览</div> -->
      <div class="save button" @click="clickSave">保存</div>
      <div class="cancel button" @click="clickCancel">取消</div>
    </div>
  </div>
</template>
<script>
import cos from '../../../utils/cos.js'
import { getCityAll } from '@/api/demand'
import {
  selectById,
  update,
  apiUpload,
  save,
  getFacilitator,
  getIssueName,
  getInformationAssociationVo,
  getSpecialistAssociationVo,
  getCompanyList,
  getTechnologyVo,
  getSmartCompanylistAssociationVo,
  checkInformationName,
  getSystemNews,
  getDataPolicy,
  getSystemPlanParam,
  getSystemInformationParam
} from '@/api/content.js'
import { selectByCompanyFullName } from '@/api/user'
import uploadLogo from '../components/uploadLogo.vue'
import uploadCover from '../components/uploadCoverOne.vue'
import uploadVideo from '../components/uploadVideo.vue'
import Editorbar from '../../../components/Editorbar.vue'
import UploadFile from './UploadFile.vue'
// import tinymceEditor from '../components/tinymceEditor'
export default {
  components: { uploadLogo, UploadFile, uploadCover, Editorbar, uploadVideo },
  props: {
    activeId: {
      type: String
    }
  },
  data() {
    return {
    issave:false,
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [], //区
      checked1: false, //本地服务
      loading: false, //loadding
      contentType: false,
      form: {
        contentType: '',
        companyId: '',
        briefIntroduction: '', // 案例简介
        customerPainPoint: '', // 客户痛点
        customerSolution: '', // 客户方案
        caseOutcome: '', // 案例成果
        relatedProducts: '', // 案例关联产品
        province: '', // 省
        city: '', // 市
        caseDate: '', // 案例日期
        policyBrief: '', // 政策简介-
        policyInterpretation: '', // 政策解读
        policyInterpretationFile: '', // 政策附件
        sceneDescription: '', // 场景描述
        serviceStatus: null,
        companyFile: null, //企业附件
        industryDynamicsFile: '', //附件行业
        productFile: null, //产品附件
        loop: 1, //播放次数
        createId: null, // 创建者ID
        createTime: '', // 创建时间
        issueNameOptions: [],
        technologyVos: [
          {
            id: '',
            technologyName: ''
          }
        ],
        facilitatorResults: [
          {
            id: null,
            informationName: null
          }
        ], // 服务商名称
        productResults: [
          {
            id: null,
            informationName: null
          }
        ], //产品
        informationCompanyVoList: [
          {
            id: null,
            enterpriseIntro: null // 简介
          }
        ], //客户企业
        specialistResults: [
          {
            id: null,

            specialistName: null
          }
        ], //专家
        informationCompanies: [
          {
            id: null,
            companyFullName: null
          }
        ], //关联企业
        informationProducts: [
          {
            id: null,
            informationId: this.activeId,
            productFunctionDescribe: null,
            productImage: ''
          }
        ],
        //应用价值
        informationProductValues: [
          {
            id: null,
            informationId: this.activeId,
            value: [],
            valueTitle: null
          }
        ],
        videoResults: null,
        videos: null,
        generalVersion: '1', //默认个性版
        id: null, //	主键
        imageList: null, // 资讯图片，多张
        informationIntro: null, //资讯描述
        informationName: null, //资讯名称
        informationType: 1, // 1应用场景，2数智技术，3竞情，4案例，5产品，6服务商
        issueId: null, // 	发布企业/人
        issueName: null,
        issueTime: null, // 	发布时间
        informationBrief: '',
        logo: null, // 	资讯logo
        resource: null, // 来源
        updateId: null, // 	更新者ID
        updateTime: null, // 更新时间
        videoList: [], // 		资讯视频，多个
        shareImage: '',
        shareTitle: '企业数智化转型的“专属参谋”',
        industryDynamicsFiles: [], //多个行业动态附件
        policyInterpretationFiles: [], //多个政策管理附附件
        dataCode: null // 行业动态编码
      },
      industryDynamicsFiles: [
        {
          name: ''
        }
      ], //内容附件数组
      policyInterpretationFiles: [
        {
          name: ''
        }
      ], //政策管理附件数组
      checked: false, //通用版
      // logo图片
      imageOneShow: '',
      imageUrlList: [],
      videoList: [],
      isClear: false,
      updatePwdRules: {
        // city: [{ required: true, message: '请设置案例地区', trigger: 'blur' }],
        // informationCompanyVoList: [{ required: true, message: '请设置客户企业', trigger: 'blur' }],

        informationName: [{ required: true, message: '请输入内容名称', trigger: 'blur' }],
        informationBrief: [{ required: true, message: '请输入一句话描述', trigger: 'blur' }],
        issueName: [{ required: true, message: '请输入发布机构/人' }],
        informationType: [{ required: true, message: '请输入资讯类型', trigger: 'change' }],
        resource: [{ required: true, message: '请输入来源', trigger: 'blur' }],
        informationIntro: [{ required: true, message: '请输入资讯描述', trigger: 'blur' }],
        policyBrief: [{ required: true, message: '请输入政策简介', trigger: 'blur' }],
        policyInterpretation: [{ required: false, message: '请输入政策解读', trigger: 'blur' }],
        sceneDescription: [{ required: true, message: '请输入场景描述', trigger: 'blur' }],
        imageList: [{ required: true, message: '请上传封面图', trigger: 'change' }],
        logo: [{ required: false, message: '请上传logo', trigger: 'blur' }],
        informationProducts: [{ required: true, message: '请设置产品功能', trigger: 'blur' }],
        productBrief: [{ required: true, message: '请设置输入产品简介', trigger: 'blur' }],
        issueTime: [{ required: true, message: '请设置发布时间', trigger: 'blur' }]

        // caseDate: [{ required: true, message: '请设置案例日期', trigger: 'blur' }]

        // productFile : [{ required: true, message: '请上传产品附件', trigger: 'blur' }],

        // informationCompanies: [{ required: true, message: '请设置所属企业', trigger: 'blur' }]
        // facilitatorResults : [{ required: true, message: '请设置关联案例', trigger: 'blur' }],
        // productResults : [{ required: true, message: '请设置关联产品', trigger: 'blur' }],
      },
      issueNameOption: [],
      facilitatorOption: [],
      InformationNameOption: [],
      specialist: [], //服务商类别资讯
      facilist: [], //产品类别资讯
      smartCompanyList: [], //产品类别资讯
      specilist: [], //专家所有
      technologyList: [] // 所有技术
    }
  },

  computed: {
    getPicLabel() {
      return function (type) {
        let txt = '封面图'
        if (type === 10) {
          txt = '案例图片'
        }
        return txt
      }
    },
    getEditBarLabel() {
      return function (type) {
        let txt = '政策解读'
        if (type === 11) {
          txt = '场景描述'
        }
        return txt
      }
    }
  },
  watch: {},
  created() {
    if (this.activeId) {
      this.getSelectById()
      // this.imageOneShow = this.form.logo
    }
    this.incity()
  },
  //方法集合
  methods: {
    // 内容类别
    contentTypeChange(val) {
      if (val) {
        this.form.contentType = 1
      } else {
        this.form.contentType = 0
      }
    },
    // 报告名称
    async querySearchInformationName(queryString, cb) {
      switch (this.form.informationType) {
        case 3:
          if (this.form.informationName) {
            const { data: res } = await getSystemNews({ name: this.form.informationName })
            if (res.resultCode == 200) {
              this.InformationNameOption = res.data
              cb(this.InformationNameOption)
            }
          } else {
            cb(this.InformationNameOption)
          }
          break
        case 7:
          if (this.form.informationName) {
            const { data: res } = await getDataPolicy({ name: this.form.informationName })
            if (res.resultCode == 200) {
              this.InformationNameOption = res.data
              cb(this.InformationNameOption)
            }
          } else {
            cb(this.InformationNameOption)
          }
          break
        case 4:
          if (this.form.informationName) {
            const { data: res } = await getSystemPlanParam({ planName: this.form.informationName })
            if (res.resultCode == 200) {
              this.InformationNameOption = res.data
              cb(this.InformationNameOption)
            }
          } else {
            cb(this.InformationNameOption)
          }
          break
        case 10:
          if (this.form.informationName) {
            const { data: res } = await getSystemPlanParam({ planName: this.form.informationName })
            if (res.resultCode == 200) {
              this.InformationNameOption = res.data
              cb(this.InformationNameOption)
            }
          } else {
            cb(this.InformationNameOption)
          }
          break
        case 5:
          if (this.form.informationName) {
            const { data: res } = await getSystemInformationParam({
              productName: this.form.informationName
            })
            if (res.resultCode == 200) {
              this.InformationNameOption = res.data
              cb(this.InformationNameOption)
            }
          } else {
            cb(this.InformationNameOption)
          }
          break
        default:
          cb(this.InformationNameOption)
      }
    },
    async handleSelectInformationName(item) {
      this.form.informationName = item.informationName
      if (item.informationName) {
        const { data: res } = await checkInformationName({
          informationName: this.form.informationName
        })
        if (res.resultCode == 500) {
          this.$confirm(res.message, '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.form.informationName = ''
          })
        }
      }
      for (let key in item) {
        if (!this.activeId) {
          if (item[key] !== null && item[key] !== undefined) {
            if (item.informationProductValues) {
              item.informationProductValues.forEach((el) => {
                this.$set(el, 'input1', '')
                this.$set(el, 'input2', '')
                el.input1 = el.value[0]
                el.input2 = el.value[1]
              })
            }

            this.form[key] = item[key]
            if (item.productCover) {
              this.form.imageList = item.productCover
            }
            if (this.form.industryDynamicsFiles) {
              //有附件时
              this.industryDynamicsFiles = []
              this.form.industryDynamicsFiles.forEach((el) => {
                this.industryDynamicsFiles.push({
                  name: el
                })
              })
            }
            if (this.form.policyInterpretationFiles) {
              this.form.policyInterpretationFile = this.form.policyInterpretationFiles[0]
              //有附件时
              this.policyInterpretationFiles = []
              this.form.policyInterpretationFiles.forEach((el) => {
                this.policyInterpretationFiles.push({
                  name: el
                })
              })
            }
          }
        }
      }
    },
    // 上传成功后的回调
    uploadFileSuccess(val) {
      this.form.policyInterpretationFile = val
    },
    // 移除附件
    removeFile() {
      // this.form.policyInterpretationFile = ''
      this.$refs.upload_child.clear()
    },
    //城市
    async incity() {
      const { data: res } = await getCityAll()
      if (res.resultCode == 200) {
        this.operatingList = res.data
      }
    },
    //选择城市
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          this.form.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.form.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.dialogSearch()
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.form.city == o.name) {
          this.form.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
    },
    // 富文本编辑器的内容
    inputVla(val) {
      // console.log(val)
    },
    // 编辑
    async getSelectById() {
      const { data: res } = await selectById({ id: this.activeId })
      // console.log(res)
      if (res.resultCode == 200) {
        // 调用数据处理
        this.handleData(res)
        // console.log(res.data.informationIntro.replace(/<[^>]+>|&[^>]+;/g, "").trim().replace('">', ""));
      }
    },
    // 数据处理
    handleData(res) {
      if (!res.data.shareImage) {
        res.data.shareImage = ''
      }
      if (res.data.imageList) {
        this.imageUrlList = res.data.imageList
      }
      //0是不可以提供本地服务，1是可以提供本地服务
      if (res.data.serviceStatus) {
        if (res.data.serviceStatus == 1) {
          this.checked1 = true
        } else {
          this.checked1 = false
        }
      }
      //行业动态附件
      if (res.data.industryDynamicsFiles) {
        //有附件时
        this.industryDynamicsFiles = []
        res.data.industryDynamicsFiles.forEach((el) => {
          this.industryDynamicsFiles.push({
            name: el
          })
        })
      }
      // 管理模块附件
      if (res.data.policyInterpretationFiles) {
        //有附件时
        this.policyInterpretationFiles = []
        res.data.policyInterpretationFiles.forEach((el) => {
          this.policyInterpretationFiles.push({
            name: el
          })
        })
      }
      //判断当没绑定时
      if (!res.data.technologyVos) {
        res.data.technologyVos = [
          {
            id: '',
            technologyName: ''
          }
        ]
      } else if (res.data.technologyVos.length === 0) {
        res.data.technologyVos = [
          {
            id: '',
            technologyName: ''
          }
        ]
      }
      if (!res.data.facilitatorResults) {
        res.data.facilitatorResults = [
          {
            id: null,
            informationName: null
          }
        ]
      } else if (res.data.facilitatorResults.length === 0) {
        res.data.facilitatorResults = [
          {
            id: '',
            informationName: ''
          }
        ]
      }
      if (!res.data.informationCompanyVoList) {
        res.data.informationCompanyVoList = [
          {
            id: null,
            companyFullName: null
          }
        ]
      } else if (res.data.informationCompanyVoList.length === 0) {
        res.data.informationCompanyVoList = [
          {
            id: '',
            companyFullName: ''
          }
        ]
      }
      if (!res.data.productResults) {
        res.data.productResults = [
          {
            id: null,
            informationName: null
          }
        ]
      } else if (res.data.productResults.length === 0) {
        res.data.productResults = [
          {
            id: '',
            informationName: ''
          }
        ]
      }
      if (!res.data.specialistResults) {
        res.data.specialistResults = [
          {
            id: null,

            specialistName: null
          }
        ]
      } else if (res.data.specialistResults.length === 0) {
        res.data.specialistResults = [
          {
            id: '',
            specialistName: ''
          }
        ]
      }
      if (!res.data.informationCompanies) {
        res.data.informationCompanies = [
          {
            id: null,
            companyFullName: null
          }
        ]
      } else if (res.data.informationCompanies.length === 0) {
        res.data.informationCompanies = [
          {
            id: '',
            companyFullName: ''
          }
        ]
      }
      if (!res.data.informationProducts) {
        res.data.informationProducts = [
          {
            id: null,
            informationId: this.activeId,
            productFunctionDescribe: null,
            productImage: ''
          }
        ]
      } else if (res.data.informationProducts.length === 0) {
        res.data.informationProducts = [
          {
            id: null,
            informationId: this.activeId,
            productFunctionDescribe: null,
            productImage: ''
          }
        ]
      }
      if (!res.data.informationProductValues) {
        res.data.informationProductValues = [
          {
            id: null,
            informationId: this.activeId,
            value: [],
            valueTitle: null
          }
        ]
      } else if (
        res.data.informationProductValues.length === 0 ||
        res.data.informationProductValues == null
      ) {
        res.data.informationProductValues = [
          {
            id: null,
            informationId: this.activeId,
            value: [],
            valueTitle: null
          }
        ]
      } else {
        res.data.informationProductValues.forEach((el) => {
          if (!el.value) return
          this.$set(el, 'input1', '')
          this.$set(el, 'input2', '')
          el.value = el.value.split(',')
          el.input1 = el.value[0]
          el.input2 = el.value[1]
        })
      }

      if (res.data.generalVersion == '0') {
        this.checked = true
      }

      // 内容类别
      if (res.data.contentType) {
        this.contentType = true
      } else {
        this.contentType = false
      }
      this.form = res.data
    },
    // 发送
    clickSend() {
      // console.log(this.form)
      // console.log('发送')
    },
    // 预览
    clickPreview() {
      // console.log('预览')
    },
    //视频上传
    uploadOneImgvideo(val) {
      this.form.videos = val
      // console.log("视频上传",this.form.videos);
    },
    removeImgvideo(val) {
      this.form.videos = val
    },
    checkedchaneg() {
      if (this.checked1) {
        this.form.serviceStatus = 1
      } else {
        this.form.serviceStatus = 0
      }
    },
    // 发布机构人

    async querySearchAsyncapt(queryString, cb) {
      if (this.form.issueName) {
        const res = await getIssueName({
          name: this.form.issueName
        })
        if (res.data.resultCode == 200) {
          this.issueNameOption = res.data.data
          cb(this.issueNameOption)
          console.log(this.issueNameOption)
        }
      } else {
        this.form.issueId = null
        cb(this.issueNameOption)
      }
    },
    //选择发布机构
    handleSelectcapt(item) {
      this.form.issueId = item.id
      this.form.issueName = item.issueName
      this.form.logo = item.issueLogo
    },
    async querySearchAsyncaptname(queryString, cb) {
      if (this.form.informationCompanies[0].companyFullName) {
        const res = await getCompanyList({
          id: this.activeId,
          type: this.form.informationType,
          queryParam: this.form.informationCompanies[0].companyFullName
        })
        if (res.data.resultCode == 200) {
          this.issueNameOptions = res.data.data
          cb(this.issueNameOptions)
        }
      } else {
        this.form.informationCompanies[0].id = null
        cb(this.issueNameOptions)
      }
    },
    //选择智选企业
    handleSelectcaptname(item) {
      this.form.companyId = item.id
      this.form.enterpriseIntro = item.enterpriseIntro
      this.form.informationCompanies[0].companyFullName = item.companyFullName
      this.form.informationCompanies[0].id = item.id
    },
    // 服务商资讯
    async querySearchAsyncapts(queryString, cb, index) {
      if (this.form.facilitatorResults[index].informationName) {
        const res = await getInformationAssociationVo({
          type: 4,
          queryParam: this.form.facilitatorResults[index].informationName
        })
        if (res.data.resultCode == 200) {
          this.specialist = res.data.data
          cb(this.specialist)
        }
      } else {
        this.form.facilitatorResults[index].id = null
        cb(this.specialist)
      }
    },

    //选择服务商资讯
    handleSelectcapts(item, index) {
      this.form.facilitatorResults[index].id = item.id
      this.form.facilitatorResults[index].informationName = item.informationName
    },
    // 产品资讯
    async querySearchAsyncapts1(queryString, cb, index) {
      if (this.form.productResults[index].informationName) {
        const res = await getInformationAssociationVo({
          type: 5,
          queryParam: this.form.productResults[index].informationName
        })
        if (res.data.resultCode == 200) {
          this.facilist = res.data.data
          cb(this.facilist)
        }
      } else {
        this.form.productResults[index].id = null
        cb(this.facilist)
      }
    },
    //选择产品资讯
    handleSelectcapts1(item, index) {
      this.form.productResults[index].id = item.id
      this.form.productResults[index].informationName = item.informationName
    },

    // 查询客户企业
    async querySearchSmartCompany(queryString, cb, index) {
      if (this.form.informationCompanyVoList[index].companyFullName) {
        const res = await getSmartCompanylistAssociationVo({
          queryParam: this.form.informationCompanyVoList[index].companyFullName
        })
        if (res.data.resultCode == 200) {
          this.smartCompanyList = res.data.data
          cb(this.smartCompanyList)
        }
      } else {
        this.form.informationCompanyVoList[index].id = null
        cb(this.smartCompanyList)
      }
    },
    //选择客户企业
    handleSelectSmartCompany(item, index) {
      this.form.informationCompanyVoList[index].id = item.id
      this.form.informationCompanyVoList[index].companyFullName = item.companyFullName
    },

    // 专家资讯
    async querySearchAsyncapts2(queryString, cb, index) {
      if (this.form.specialistResults[index].specialistName) {
        const res = await getSpecialistAssociationVo({
          queryParam: this.form.specialistResults[index].specialistName
        })
        if (res.data.resultCode == 200) {
          this.specilist = res.data.data
          cb(this.specilist)
        }
      } else {
        this.form.specialistResults[index].id = null
        cb(this.specilist)
      }
    },
    //选择专家资讯
    handleSelectcapts2(item, index) {
      this.form.specialistResults[index].id = item.id
      this.form.specialistResults[index].specialistName = item.name
    },

    // 查询关联技术
    async querySearchTechnology(queryString, cb, index) {
      if (this.form.technologyVos[index].technologyName) {
        const { data: res } = await getTechnologyVo({
          informationName: this.form.technologyVos[index].technologyName
        })
        if (res.resultCode == 200) {
          this.technologyList = res.data
          console.log(this.technologyList)
          cb(this.technologyList)
        }
      } else {
        this.form.technologyVos[index].id = null
        cb(this.technologyList)
      }
    },
    //选中关联技术
    handleSelectTechnology(item, index) {
      this.form.technologyVos[index].id = item.id
      this.form.technologyVos[index].technologyName = item.technologyName
    },
    // 查询智参企业
    async querySearchAsyncapts3(queryString, cb, index) {
      if (this.form.informationCompanies[index].companyFullName) {
        const res = await getCompanyList({
          type: this.form.informationType,
          queryParam: this.form.informationCompanies[index].companyFullName
        })
        if (res.data.resultCode == 200) {
          this.specilist3 = res.data.data
          cb(this.specilist3)
        }
      } else {
        this.form.informationCompanies[index].id = null
        cb(this.specilist3)
      }
    },

    //选择智参企业
    handleSelectcapts3(item, index) {
      this.form.informationCompanies[index].id = item.id
      this.form.informationCompanies[index].companyFullName = item.companyFullName
    },
    //新增服务商
    addareas() {
      this.form.facilitatorResults.push({
        id: null,
        informationName: null
      })
    },
    //删除服务商
    delareas(index) {
      this.form.facilitatorResults.splice(index, 1)
    },
    //新增产品
    addareasprod() {
      this.form.productResults.push({
        id: null,
        informationName: null
      })
    },
    //删除产品
    delareasprod(index) {
      this.form.productResults.splice(index, 1)
    },

    //新增客户企业
    addCustomEnterprise() {
      this.form.informationCompanyVoList.push({
        id: null,
        informationName: null
      })
    },
    //删除客户企业
    delCustomEnterprise(index) {
      this.form.informationCompanyVoList.splice(index, 1)
    },

    //新增专家
    addareaspeci() {
      this.form.specialistResults.push({
        id: null,
        specialistName: null
      })
    },
    //删除专家
    delareaspeci(index) {
      this.form.specialistResults.splice(index, 1)
    },
    //新增技术
    addTechnology() {
      this.form.technologyVos.push({
        id: null,
        technologyName: null
      })
    },
    //删除技术
    delTechnology(index) {
      this.form.technologyVos.splice(index, 1)
    },
    //新增企业
    addenterprise() {
      this.form.informationCompanies.push({
        id: null,
        companyFullName: null
      })
    },
    delenterprise(index) {
      this.form.informationCompanies.splice(index, 1)
    },
    //新增产品功能
    addDescribe() {
      this.form.informationProducts.push({
        id: null,
        informationId: this.activeId,
        productFunctionDescribe: null,
        productImage: ''
      })
    },
    delDescribe(index) {
      this.form.informationProducts.splice(index, 1)
    },
    //新增应用价值
    addvalueTitle() {
      this.form.informationProductValues.push({
        id: null,
        informationId: this.activeId,
        value: [],
        valueTitle: null
      })
    },
    delvalueTitle(index) {
      this.form.informationProductValues.splice(index, 1)
    },
    //新增行业动态附件
    addUpload() {
      switch (this.form.informationType) {
        case 3:
          this.industryDynamicsFiles.push({
            name: ''
          })
          break
        case 7:
          this.policyInterpretationFiles.push({
            name: ''
          })
          break
        default:
        // 如果expression不匹配任何case，则执行这里的代码
      }
    },
    //删除行业动态附件
    delUpload(index) {
      switch (this.form.informationType) {
        case 3:
          this.industryDynamicsFiles.splice(index, 1)
          break
        case 7:
          this.policyInterpretationFiles.splice(index, 1)
          break
        default:
        // 如果expression不匹配任何case，则执行这里的代码
      }
    },
    //上传pdf
    async beforeUpload(file) {
      this.file = file
      this.filename = file.name

      const extension = file.name.split('.').splice(-1) == 'pdf'
      if (!extension) {
        this.$message.warning('上传模板只能是pdf格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    upload(params) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: 'ginseng-wisdom-1300257084' /* 填写自己的 bucket，必须字段 */,
          Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */,
          Key:
            'system_img/' +
            params.file.name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
        })
        .then((res) => {
          if (res.statusCode === 200) {
            this.form.companyFile = res.Location
            loading.close()
          }
        })
        .catch((err) => {
          loading.close()
          console.log('上传出错', err)
        })
      // const res = await apiUpload(form)
      // if (res.data.resultCode === 200) {
      //   this.form.companyFile = res.data.data
      // }
    },
    //上传pdf
    async beforeUploadprod(file) {
      this.file = file
      this.filename = file.name

      const extension = file.name.split('.').splice(-1) == 'pdf'
      if (!extension) {
        this.$message.warning('上传模板只能是pdf格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    uploadprod(params, index) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: 'ginseng-wisdom-1300257084' /* 填写自己的 bucket，必须字段 */,
          Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */,
          Key:
            'system_img/' +
            params.file.name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
        })
        .then((res) => {
          if (res.statusCode === 200) {
            this.form.productFile = res.Location
            loading.close()
          }
        })
        .catch((err) => {
          loading.close()
          console.log('上传出错', err)
        })
    },
    //上传行业动态pdf
    async beforeUploadprods(file) {
      this.file = file
      this.filename = file.name
      let format = file.name.split('.')[1]

      let formatList = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']

      const extension = formatList.indexOf(format)
      if (extension == -1) {
        this.$message.warning('上传模板不支持此格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传行业动态
    uploadprods(params, index) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: 'ginseng-wisdom-1300257084' /* 填写自己的 bucket，必须字段 */,
          Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */,
          Key:
            'system_img/' +
            params.file.name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
        })
        .then((res) => {
          if (res.statusCode === 200) {
            switch (this.form.informationType) {
              case 3:
                this.industryDynamicsFiles[index].name = res.Location
                loading.close()
                break
              case 7:
                this.policyInterpretationFiles[index].name = res.Location
                loading.close()
                break
              default:
            }
          }
        })
        .catch((err) => {
          loading.close()
          console.log('上传出错', err)
        })
    },
    removeImgDianprod(val, index) {
      this.form.informationProducts[index].productImage = ''
    },
    uploadOneImgShowDianprod(val, index) {
      this.form.informationProducts[index].productImage = val
    },
    clcikChecked() {
      if (this.checked == true) {
        this.form.generalVersion = '0'
      } else if (this.checked == false) {
        this.form.generalVersion = '1'
      }
      // console.log(this.checked)
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.shareImage = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.shareImage = ''
    },
    dislodge() {
      if (this.form.facilitatorResults) {
        if (!this.form.facilitatorResults[0].informationName) {
          this.form.facilitatorResults = null
        } //案例
      }
      if (this.form.productResults) {
        if (!this.form.productResults[0].informationName) {
          this.form.productResults = null
        } //产品
      }
      if (this.form.specialistResults) {
        if (!this.form.specialistResults[0].specialistName) {
          this.form.specialistResults = null
        } //专家
      }
      if (this.form.informationCompanies) {
        if (!this.form.informationCompanies[0].companyFullName) {
          this.form.informationCompanies = null
          this.form.companyId = ''
        } //资讯关联企业
      }
      if (this.form.informationProducts) {
        if (!this.form.informationProducts[0].productFunctionDescribe) {
          this.form.informationProducts = null
        } //产品企业
      }
      if(this.form.informationType==3){
        if (this.industryDynamicsFiles) {
        if (!this.industryDynamicsFiles[0].name) {
          //行业附件
          this.form.industryDynamicsFiles = null
        } else {
          this.form.industryDynamicsFiles = []
          this.industryDynamicsFiles.forEach((el) => {
            this.form.industryDynamicsFiles.push(el.name)
          })
        }
      }
      }
     if(this.form.informationType ==7){
      if (this.policyInterpretationFiles.length > 0) {
        if (!this.policyInterpretationFiles[0].name) {
          //行业附件
          this.form.policyInterpretationFiles = null
        } else {
          this.form.policyInterpretationFiles = this.policyInterpretationFiles.map(
            (item) => item.name
          )
        }
      }
     }
      
      

      if (this.form.informationProductValues) {
        if (this.form.informationProductValues[0].valueTitle == null) {
          this.form.informationProductValues = null
        } else {
          this.form.informationProductValues.forEach((el, index) => {
            el.value = []
            if (el.input1) {
              el.value.push(el.input1)
              delete el.input1
            }
            if (el.input2) {
              el.value.push(el.input2)
              delete el.input2
            }
            el.value = el.value.toString()
          })
        }
      } else {
        this.form.informationProductValues = null
      }
    },

    // 保存
    clickSave() {
      
      
      if(this.issave){
        return
      }
    
      if (this.contentType) {
        this.form.contentType = 1
      } else {
        this.form.contentType = 0
      }
      if (this.imageUrlList.length > 0) {
        this.form.imageList = this.imageUrlList
      }
    
      const bol = this.form.informationCompanyVoList.length > 0 || this.form.informationType !== 10
      if (bol) {
        this.issave=true
       
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.dislodge()
          
            if (this.form.id == null) {
              save(this.form).then((response) => {
                if (response.data.resultCode == 200) {
                  this.issave=false
                  this.$message.success('新增成功')
                  this.$router.push({
                    path: '/content/list'
                  })
                } else {
                  this.issave=false
                  this.$message.error(response.data.message)
                }
              })
            } else {
              this.loading = true
              update(this.form).then((response) => {
                if (response.data.resultCode == 200) {
                  this.issave=false
                  this.loading = false
                  this.$message.success('编辑成功')
                  this.$router.push({
                    path: '/content/list'
                  })
                } else {
                  this.issave=false
                  this.loading = false
                  this.$message.error(response.data.message)
                }
              })
            }
          }else{
            this.issave=false
          }
        })
      } else {
        this.issave=false
        this.$message.error('请设置客户企业!')
      }
    },
    // 取消
    clickCancel() {
      if (this.$route.query.type) {
        this.$router.push({
          path: '/content/list',
          query: {
            changeNum: true
          }
        })
      } else {
        this.$router.go(-1)
      }

      // this.$router.go(-1)
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      // this.imageOneShow = value
      this.form.logo = value
    },

    //多个图片上传显示
    uploadOneImg(val) {
      this.imageUrlList = []

      val.forEach((item) => {
        this.imageUrlList.push(item.url)
      })
      this.$refs.form.clearValidate('imageList')
    },
    // 移除
    removeImg(val) {
      this.imageUrlList = []
      val.forEach((item) => {
        this.imageUrlList.push(item.url)
      })
    }
  }
}
</script>
<style scoped lang="less">
.row {
  width: 100%;
  display: flex;
}
.row .el-select {
  width: 200px;
  ::v-deep .el-input {
    width: 100%;
  }
}
.input_upload_box {
  border: 1px solid #c4c4c4;
  width: 600px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 2px;
  padding-right: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  i {
    cursor: pointer;
    color: #999;
  }
  // .txt {
  //   width: 100%;
  //   font-size: 14px;
  //   line-height: 40px;
  //   color: #999;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   text-overflow: ellipsis;
  // }
  .upload_box {
    margin-left: auto;
  }
  ::v-deep .el-input__inner {
    height: 38px;
    line-height: 38px;
    border: none;
  }
}
.number_top {
  width: 96px;
  height: 40px;
  background: #f4f4f4;
  border-radius: 2px;
  text-align: center;
  line-height: 40px;
  color: #bebebe;
  margin-bottom: 20px;
}
.formBox {
  padding-top: 24px;
  width: 100%;
  height: 100%;
  position: relative;
  .loading_po {
    position: absolute;
    bottom: 500px;
    width: 100%;
    z-index: 99999999 !important;
  }
  .img_tips {
    font-size: 12px;
    // color: #6ba1ff;
    color: #cfd3db;
  }
  .flex {
    display: flex;
    span {
      display: block;
      padding-left: 10px;
    }
  }
  .h4_share {
    padding: 50px 50px 0px 50px;
    color: #146aff;
  }

  .inputBox {
    height: 40px;
    display: flex;
  }
  .general_inputs {
    width: 585px;
    border: 1px solid #d4d4d4;
    padding: 14px 20px;
    .input_flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 0px;
      .valueType_flex {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
      }
      span {
        padding-left: 15px;
        font-size: 20px;
        cursor: pointer;
      }
      .el_buttom {
        position: relative;
        left: -110px;
      }
    }
  }
  ::v-deep .textarea .el-textarea__inner {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 480px;
    font-size: inherit;
    color: #606266;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  ::v-deep .percentage .el-input {
    width: 200px;
    height: 40px;
  }

  ::v-deep .textarea .el-textarea {
    position: relative;
    display: inline-block;
    width: 480px;
    vertical-align: bottom;
    font-size: 14px;
  }
  /deep/.el-input {
    width: 600px;
    height: 40px;
  }

  .labenBox {
    width: 120px;
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;

    .label {
      color: #333333;
      font-size: 14px;
      margin-right: 2px;
    }
    .span {
      color: #f05252;
    }
  }

  .logo_box {
    display: flex;
    .label_box {
      display: flex;
      margin-right: 12px;
      width: 120px;
      justify-content: flex-end;
      .logo_label {
        color: #333333;
        font-size: 14px;
        margin-right: 2px;
      }
      .span {
        color: #f05252;
      }
    }
  }

  .cover {
    display: flex;
    flex-direction: column;
  }

  .picker {
    width: 240px !important;
  }
  /deep/.el-radio-group {
    display: flex;
    // flex-direction: column;
    align-items: center;
  }
  /deep/.el-radio__input {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__label {
    font-size: 14px;
    color: #333333;
  }
  .file_flex {
    margin-bottom: 10px;
    width: 600px;
    display: flex;
    justify-content: space-between;
  }
  .editInformation {
    margin-top: 40px;
    margin-left: 58px;
    color: #999999;
    font-size: 14px;

    .item {
      margin-bottom: 6px;
    }
  }
  .button_box {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;

    .send {
      background-color: #f79646;
      color: #fff;
      cursor: pointer;
    }
    .preview {
      background-color: #37cc7e;
      color: #fff;
      cursor: pointer;
    }
    .save {
      background-color: #4e93fb;
      color: #fff;
      cursor: pointer;
    }
    .cancel {
      background-color: #fff;
      color: #4e93fb;
      cursor: pointer;
      border: 1px solid #4e93fb;
    }
    .button {
      width: 112px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }
  .editorbar_box {
    width: 50%;
    min-width: 600px;
    min-height: 400px;
    .editBar {
      width: 100%;
      // height: 487px;
    }
  }
  .editBarBox {
    width: 100%;
    display: flex;

    .editbar_text {
      width: 130px;
      display: flex;
      justify-content: flex-end;
      .text_a {
        margin-right: 2px;
      }
      .span {
        margin-right: 12px;
        color: #f05252;
      }
    }

    .editBar {
      // width: 686px;
      // width: 100%;
      width: 375px;
      height: 487px;
    }
  }
}
.thumbnail {
  display: flex;
  color: #bebebe;
}
.thumbnail img {
  width: 143.23px;
  height: 81.63px;
  border-radius: 4px;
}
.thumbnail video {
  width: 160.28px;
  height: 91.35px;
  border-radius: 4px;
}

.thumbnail div {
  margin-right: 20px;
}
.thumbnail_box div {
  display: flex;
  flex-direction: column;
  height: 30px;
}
.number_loop {
  display: flex;
  align-items: flex-end;
}
// /deep/.w-e-menu {
//   z-index: 2 !important;
// }
// /deep/.w-e-text-container {
//   z-index: 1 !important;
// }
// /deep/.w-e-toolbar{
//   z-index: 1 !important;
// }

::v-deep .el-button--primary.is-plain {
  color: #448aff;
  background: #ecf3ff;
  border-color: #b4d0ff;
  border: none;
}
::v-deep .name_form .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}

::v-deep .number_loop .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 180px;
}
::v-deep .editor .text {
  border: 1px solid #ccc;
  min-height: 390px;
  max-height: 390px;
}
::v-deep .w-e-text-container .w-e-text {
  max-height: 390px;
}
::v-deep .txtree .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 600px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 600px;
  vertical-align: bottom;
  font-size: 14px;
}
::v-deep .editor {
  width: 600px;
}
.pictures /deep/.el-upload.el-upload--picture-card {
  width: 238px;
  height: 134px;
  line-height: 134px;
}
/deep/button.el-button.el-button--primary.el-button--small {
  background-color: rgba(78, 147, 251, 0.1);
  border-radius: 2px;
  border: none;
  text-align: center;
  color: #4e93fb;
}
// 星号放右边
::v-deep {
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    display: none;
  }
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
    content: '*';
    color: #f56c6c;
    margin-left: 4px;
  }
}
.issueTime {
  display: flex;
}
</style>
